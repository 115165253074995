<template>
<section class="outContainer">
  <!-- 搜索 -->
  <div class="search">
      <!-- 订单状态 -->
      <el-input class="each" placeholder="请输入问题关键字" v-model="searchInfo.param.question"></el-input>
      <el-input class="each" placeholder="请输入答案关键字" v-model="searchInfo.param.answer"></el-input>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="toSearch">查询</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close"  @click="backAll">清空搜索条件</el-button>
      <el-button size="mini" @click="handleAdd(null)">新增</el-button>
  </div>
  <div class="tableContainer">
    <!-- 表格 -->
    <el-table
      :data="customerServiceInfo"
      style="width: 100%"
      height="100%"
      class="table"
      fit
    >
      <el-table-column align='center' label="问题" prop="question">
      </el-table-column>
      <el-table-column align='center' label="答案" prop="answer">
      </el-table-column>
      <el-table-column align='center' label="是否微信端预展示" prop="isShow">
        <template slot-scope="scope">
          {{ scope.row.isShow ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column align='center' label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleAdd(scope.row)">编辑</el-button>
          <el-button size="mini" @click="handleDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页-->
    <PaginationVue v-bind:child-msg="searchInfo" @callFather="callFather"></PaginationVue>
    <!-- 新增菜单 -->
    <el-drawer :title="row ? '编辑' : '新增'"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
      size='50%'
      style="overflow:auto">
      <add v-if="addDrawer" @close='handleClose' ref='newForm' :row="row"></add>
    </el-drawer>
  </div>
</section>
</template>

<script>
import{ getCustomerMsgList,delCustomerMsg} from '@/api/public.js';
import add from './add.vue';
import PaginationVue from '@/components/Pagination.vue';
export default {
  data() {
    return {
      customerServiceInfo:[],
      searchInfo:{ // 查询信息
        pageNo:1,
        pageSize:10,
        total:0,
        param:{
          answer: "",
          question: ""
        }
      },
      row:null,
      addDrawer:false,
      direction:'rtl',
    }
  },

  components: {add,PaginationVue},

  computed: {},

  mounted() {
    this.init();
  },
  methods: {
    // 数据初始化
    async init(){
      await getCustomerMsgList(this.searchInfo).then((res)=>{
        if(res.success){
          this.customerServiceInfo = res.data.list;
          this.searchInfo.total = res.data.total;
        }else{
          this.customerServiceInfo = [];
          this.searchInfo.total = 0;
          this.$message({
            message:res.msg,
            type:'fail',
          })
        }
      })
    },
    // 删除
    async handleDel(row){
        await this.$confirm('确认删除该问答吗？').then(() => {
          delCustomerMsg({param:row.id}).then(res=>{
            if(res.success){
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.init();
            }else{
              this.$message({
                message: res.msg,
                type: "success",
              });
            }
          })
        })
    },
    // 条件查询
    toSearch(){
      this.searchInfo.pageNo=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.param = {}
      this.init();
    },
    selectStatus(e){},
    // 打开新增
    handleAdd(row){
      this.row = row;
      this.addDrawer=true;
    },
    handleClose(){
      this.row = null;
      this.addDrawer=false;
      this.init();
    },
    // 分页
    callFather(parm){
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
  @import "@/static/default";
/* 搜索 */
.search{
  margin-bottom: 30px;
    .inputs{
      display: flex;
    }
    .wxName,.select,.timeSelect{
      width: 200px;
      margin-right: 20px;
    }
    .Btns{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
  /* 添加 */
  .add{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .add button{
    position: absolute;
    right:20px;
    top:10px;
    width: 100px;
  }
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }
/* 内容 */
  .container{
    background-color: #fff !important;
    padding:0 32px;
    .toolbar{
      background: #fff;
      width:calc(100% + 64px);
      padding:16px 32px 26px 0;
      margin-left:-32px;
    }
  }
</style>
